import React from 'react';
import { Center, Divider } from '@chakra-ui/react';

function DividerComponent({ ...rest }) {
  return (
    <Center>
      <Divider
        margin="8px 0 16px 0"
        maxW="80px"
        opacity={1}
        height="1px"
        borderColor="bgSecondary"
        borderBottomWidth="2px"
        {...rest}
      />
    </Center>
  );
}

export default DividerComponent;
