import React from 'react';
import { bool, func, string } from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';

import useLang from '@hooks/useLang';
import loadable from '@loadable/component';

import {
  Box,
  Button,
  Center,
  Heading,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  Image,
  Text,
} from '@chakra-ui/react';
import txt from './locales';

const LoadableGallerry = loadable(() =>
  import(/* webpackChunkName: "invitato-gallery-photos" */ './Gallery'),
);

import { IMG_LOGO_INITIAL, BG_PHOTO_CONTENT, IMG_PATTERN_LEFT } from '@/constants/assets';
import { BUTTON_PROPS, DRAWER_COLOR } from '@/constants/colors';

import { photos as photos2 } from './api/photos-2';
import { photos as photos3 } from './api/photos-2';

function PhotoContent({ isOpen, onClose }) {
  const lang = useLang();

  return (
    <Drawer size="full" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton color="transparent" {...BUTTON_PROPS} />
        <DrawerBody
          width="100%"
          padding="0"
          bgColor="drawerColor"
          background={`linear-gradient(180deg, ${DRAWER_COLOR} 65%, rgb(255,255,255) 200%)`}
        >
          <Box
            bgImage={`url(${BG_PHOTO_CONTENT})`}
            height="370px"
            bgSize="cover"
            bgPosition="center"
          >
            {false && (
              <Center paddingTop="24px">
                <Image src={IMG_LOGO_INITIAL} maxWidth="60px" />
              </Center>
            )}
          </Box>
          {/* Images Cover */}
          <Box
            padding="32px 24px"
            bgImage={`url(${IMG_PATTERN_LEFT})`}
            bgRepeat="repeat-y"
            bgSize="80%"
            textAlign="center"
            color="mainColorText"
          >
            <Text margin="24px 0" fontFamily="heading">
              {lang ==="id"
                ?`Mahasuci (Allah) yang telah menciptakan semuanya berpasang-pasangan, baik dari apa yang ditumbuhkan 
                oleh bumi dan dari diri mereka sendiri, maupun dari apa yang tidak mereka ketahui.`
                :`Exalted is He who created all pairs, from what the earth grows and from themselves and 
                from that which they do not know.`
              } <br />
              - Q.S. Yasin: 36 -
            </Text>
            {/* 2 Section */}
            <Box padding="24px">
              {isOpen && <LoadableGallerry data={photos2} />}
            </Box>
            {/* 3 Section */}
            {false && (
              <Box padding="24px" width="100%">
                <Heading
                  marginBottom="24px"
                  fontFamily="headingAlternative"
                  size="2xl"
                  fontWeight="normal"
                >
                  Javanesse
                </Heading>
                {isOpen && <LoadableGallerry data={photos3} />}
              </Box>
            )}
          </Box>
          <Center paddingBottom="80px">
            <Button
              leftIcon={<BiArrowBack />}
              size="sm"
              fontWeight="normal"
              color="alternativeDark"
              bgColor="bgSecondary"
              onClick={() => onClose()}
              {...BUTTON_PROPS}
            >
              {txt.back[lang]}
            </Button>
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

PhotoContent.propTypes = {
  lang: string.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default PhotoContent;