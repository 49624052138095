import React from 'react';
import { Box, Center, Text, Link, Heading, Divider } from '@chakra-ui/react';

import Image from '@/components/Common/LazyImage';
import WithAnimation from '@/components/Common/WithAnimation';
import useInvitation from '@hooks/useInvitation';

import { SOUND_BY, SOUND_URL, THE_BRIDE, URL_IG_INVITATO } from '@/constants';
import { IMG_LOGO_FOOTER, LOGO_INVITATO } from '@/constants/assets';
import { FOOTER_LINK_PROPS } from './types';

import txt from './locales';
import useLang from '@hooks/useLang';
import { ENABLE_COLLABORATION, MUSLIM_INVITATION, SELECTED_VENDOR_COLLABORATION } from '@/constants/feature-flags';

function FooterSection() {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box bgColor="bgPrimary">
      <Box textAlign="center" color="mainColorText" padding="42px 32px">
        <WithAnimation>
          <Text margin="24px 0 0 0">
            {isInvitation ? (
              <>{txt.penutupInvitation[lang]}</>
            ) : (
              <>{txt.penutupAnnouncement[lang]}</>
            )}
          </Text>
        </WithAnimation>
        {MUSLIM_INVITATION &&
          <WithAnimation>
            <Text fontSize="md" fontFamily="body" color="mainColorText">
              Wassalamualaikum Warahmatullahi Wabarakatuh.
            </Text>
          </WithAnimation>
        }
        <WithAnimation>
          <Center margin="24px 0 24px">
            <Image
              src={IMG_LOGO_FOOTER}
              height="130px"
            />
          </Center>
        </WithAnimation>
        <WithAnimation>
          <Heading marginTop="0" size="2xl" fontWeight="normal" marginBottom="32px">
            {THE_BRIDE}
          </Heading>
        </WithAnimation>
      </Box>
      {/* Title and Desc Section */}
      <Box bgColor="#0b2923" textAlign="center" width="100%" maxW="500px">
        <Box padding="24px">
          {/* INVITATO SECTION */}
          <Box>
            <Center>
              <Link href={URL_IG_INVITATO} target="_blank">
                <Image src={LOGO_INVITATO} maxWidth="110px" margin="0" />
              </Link>
            </Center>
            <Text textAlign="center" color="mainColorText" fontSize="sm" margin="-4px 0 12px 0">
              Created with love by Invitato
            </Text>
            {ENABLE_COLLABORATION &&
            <Box margin="-4px 0 18px">
              <Center>
                <Divider
                  margin="8px 0 16px"
                  maxWidth="130px"
                  opacity="1"
                  height="1px"
                  bgColor="mainColorText"
                  border="none"
                />
              </Center>
              <Center margin="6px 0 2px">
                <Link href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`} target="_blank">
                <Image
                        src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                        {...SELECTED_VENDOR_COLLABORATION.imgProps}
                      />
                </Link>
              </Center>
              <Center>
                <Text color="mainColorText" align="center" fontSize="xs">
                In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
                </Text>
              </Center>
            </Box>
            }
            <Text {...FOOTER_LINK_PROPS}>
              {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Right Reserved`}
            </Text>
            <Link {...FOOTER_LINK_PROPS} href={SOUND_URL}>
              <Text>{`Song by ${SOUND_BY}`}</Text>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(FooterSection);
