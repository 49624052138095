import get from '@invitato/helpers/dist/getLocale';

export default {
  thanks: get('Terima Kasih', 'Thank You!'),
  penutupInvitation: get(
    'Menjadi sebuah kehormatan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir secara langsung dalam acara pernikahan kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. Sampai jumpa di hari pernikahan kami!' , 
    'It will be a joy for us if you are able to be attend on our wedding day. Thank you for all the kind words, prayers, and attentions given. See you on our wedding day!'),
  penutupAnnouncement: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i tetap berkenan memberikan doa restu secara online untuk pelaksanaan hari pernikahan kami. Anda akan tetap ada di hati dan pikiran kami, meski kami terpaksa tidak bisa mengundang. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan.', 
    `It will be a joy for us if you are still willing to give your blessing from afar. Please know that you are in our thoughts and will be in our hearts, even though we are deeply sorry, we can't invite you to the ceremony. Thank you for all the words, prayers, and attention given.`)
};