import P1 from '@/images/first/1.jpg';
import P2 from '@/images/first/2.jpg';
import P3 from '@/images/first/3.jpg';
import P4 from '@/images/first/4.jpg';
// import P5 from '@/images/first/5.jpg';
// import P6 from '@/images/first/6.jpg';
// import P7 from '@/images/first/7.jpg';
// import P8 from '@/images/first/8.jpg';
// import P9 from '@/images/first/9.jpg';
// import P10 from '@/images/first/10.jpg';
// import P11 from '@/images/first/11.jpg';
// import P12 from '@/images/first/12.jpg';
// import P13 from '@/images/first/13.jpg';
// import P14 from '@/images/first/14.jpg';
// import P15 from '@/images/first/15.jpg';
// import P16 from '@/images/first/16.jpg';

export const photos = [
  // {
  //   original:
  //     'https://user-images.githubusercontent.com/10141928/153749171-f4517fb8-4922-4f88-9529-3566eb1bba5e.jpg',
  //   // thumbnail: P1,
  // },
  {
    original: P1,
    // thumbnail: P2,
  },
  {
    original: P2,
    // thumbnail: P2,
  },
  {
    original: P3,
    // thumbnail: P3,
  },
  {
    original: P4,
    // thumbnail: P4,
  },
  // {
  //   original: P5,
  //   // thumbnail: P5,
  // },
  // {
  //   original: P6,
  //   // thumbnail: P6,
  // },
  // {
  //   original: P7,
  //   // thumbnail: P7,
  // },
  // {
  //   original: P8,
  //   // thumbnail: P8,
  // },
  // {
  //   original: P9,
  //   // thumbnail: P9,
  // },
  // {
  //   original: P10,
  //   // thumbnail: P10,
  // },
  // {
  //   original: P11,
  //   // thumbnail: P11,
  // },
  // {
  //   original: P12,
  //   // thumbnail: P12,
  // },
  // {
  //   original: P13,
  //   // thumbnail: P13,
  // },
  // {
  //   original: P14,
  //   // thumbnail: P14,
  // },
  // {
  //   original: P15,
  //   // thumbnail: P15,
  // },
  // {
  //   original: P16,
  //   // thumbnail: P16,
  // },
  // {
  //   original: P17,
  //   // thumbnail: P17,
  // },
  // {
  //   original: P18,
  //   // thumbnail: P18,
  // },
  // {
  //   original: P19,
  //   // thumbnail: P19,
  // },
  // {
  //   original: P20,
  //   // thumbnail: P20,
  // },
  // {
  //   original: P21,
  //   // thumbnail: P21,
  // },
  // // {
  // //   original: P22,
  // //   // thumbnail: P22,
  // // },
  // {
  //   original: P23,
  //   // thumbnail: P23,
  // },
  // {
  //   original: P24,
  //   // thumbnail: P24,
  // },
  // {
  //   original: P25,
  //   // thumbnail: P25,
  // },
  // {
  //   original: P26,
  //   // thumbnail: P26,
  // },
  // {
  //   original: P27,
  //   // thumbnail: P27,
  // },
  // {
  //   original: P28,
  //   // thumbnail: P28,
  // },
  // {
  //   original: P29,
  //   // thumbnail: P29,
  // },
  // {
  //   original: P30,
  //   // thumbnail: P30,
  // },
  // {
  //   original: P31,
  //   // thumbnail: P31,
  // },
  // {
  //   original: P32,
  //   // thumbnail: P32,
  // },
];
