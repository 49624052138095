import React from 'react';
import { string, oneOfType, object } from 'prop-types';
import { Box, Text, Heading } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

function WeddingDetail({
  dresscode,
  title,
  titleProps,
  subTitle,
  date,
  location,
  locationRoad,
  time,
  notes,
}) {
  return (
    <Box color="mainColorText" marginBottom="24px">
      <WithAnimation>
        <Heading
          textAlign="center"
          size="xl"
          fontWeight="normal"
          margin="24px 0"
          {...titleProps}
        >
          {title}
        </Heading>
      </WithAnimation>
      <WithAnimation>
        <Text margin="8px 0" fontSize="sm" textAlign="center" textTransform="uppercase">
          {subTitle}
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Box margin="16px 0" fontSize="sm" textAlign="center" textTransform="uppercase">
          <Text fontWeight="bold">{date}</Text>
          <Text fontWeight="bold">{time}</Text>
          {dresscode && <Text fontSize="xs">{`Dresscode: ${dresscode}`}</Text>}
        </Box>
      </WithAnimation>
      {notes && (
        <WithAnimation>
          <Text margin="8px 0" fontSize="sm" textAlign="center" textTransform="uppercase">
            <span dangerouslySetInnerHTML={{ __html: notes }} />
          </Text>
        </WithAnimation>
      )}
      {location && (
        <WithAnimation>
          <Text fontSize="sm" fontWeight="bold" textAlign="center" textTransform="uppercase">
            {location}
          </Text>
        </WithAnimation>
      )}
      {locationRoad && (
        <WithAnimation>
          <Text fontSize="xs" textAlign="center" textTransform="uppercase">
            {locationRoad}
          </Text>
        </WithAnimation>
      )}
    </Box>
  );
}

WeddingDetail.propTypes = {
  date: string.isRequired,
  notes: string,
  dresscode: string,
  subTitle: oneOfType([string, object]),
  time: string.isRequired,
  title: string.isRequired,
  location: string,
  locationRoad: string,
  titleProps: object,
};

WeddingDetail.defaultProps = {
  notes: '',
  dresscode: '',
  titleProps: {},
  location: '',
  locationRoad: '',
};

export default React.memo(WeddingDetail);
