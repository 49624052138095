import get from '@invitato/helpers/dist/getLocale';

export default {
  seeLocation: get('Lihat Lokasi', 'See Location'),
  remindMe: get('Ingatkan Acara', 'Remind Me'),
  back: get('Kembali', 'Back'),
  acara: get('Detail Acara', 'The Vows Detail'),
  Akad: get('Akad Nikah', 'Akad Nikah'),
  Resepsi: get('Resepsi', 'Wedding Reception'),
  detailAkad: get(
    'AKAD NIKAH DILAKSANAKAN PADA:', 
    'AKAD NIKAH WAS HELD ON:'
  ),
  resepsiDetai: get(
    'KAMI MOHON KEHADIRAN BAPAK/IBU/SAUDARA/I PADA ACARA NGUNDUH MANTU YANG AKAN DILAKSANAKAN PADA:', 
    'We request the presence of Mr/Mrs/Ms in the Ngunduh Mantu on:'
  )
};
