import React from 'react';
import { arrayOf, bool, object } from 'prop-types';
import { AiFillPushpin, AiOutlineArrowDown } from 'react-icons/ai';
import {
  Box,
  Skeleton,
  Flex,
  SkeletonCircle,
  Text,
  IconButton,
  Center,
  Divider,
} from '@chakra-ui/react';

import { styWishlishWrapper } from './styles';

function Wishlist({ wishlistData, loading }) {
  const renderLoading = () => {
    const result = [];

    for (let i = 0; i < 2; i++) {
      result.push(
        <Flex alignItems="center" key={`loader-${i}`}>
          <Box marginRight="16px">
            <SkeletonCircle height="40px" width="40px" />
          </Box>
          <Box width="80%">
            <Box boxShadow="md" p={4} borderEndRadius="3xl" borderTopRadius="3xl">
              <Skeleton m="2" height="14px" width="50px" />
              <Skeleton m="2" height="14px" width="100%" />
              <Skeleton m="2" height="14px" width="200px" />
            </Box>
          </Box>
        </Flex>,
      );
    }

    return result;
  };

  const getWishItem = (item, index) => {
    return (
      <Flex alignItems="top" marginTop="16px" boxShadow="sm" key={`wishlist-${index}`}>
        <Box width="100%">
          <Box padding="4px 8px" bgColor="bgSecondary" width="100%">
            <Flex alignItems="center">
              <Text marginRight="8px" fontWeight="bold" fontSize="md" fontFamily="body">
                {item.nama}
              </Text>
              {item.pinByAdmin === 1 && <AiFillPushpin size={12} />}
            </Flex>
          </Box>
          <Divider height="1px" bgColor="bgAlternative" />
          <Box padding="4px 8px" bgColor="bgSecondary" width="100%" fontFamily="body">
            <Text fontSize="sm">{item.ucapan}</Text>
            <Text fontSize="xs" color="blackAlpha.700" marginTop="4px">
              {item.time}
            </Text>
          </Box>
        </Box>
      </Flex>
    );
  };

  const renderWishlist = () => {
    let finalResult = [];
    let wishlist = [];
    let pinnedWishlist = [];

    wishlistData.forEach((item, index) => {
      if (item.pinByAdmin === 1) {
        pinnedWishlist.push(getWishItem(item, index));
      } else {
        wishlist.push(getWishItem(item, index));
      }
    });

    wishlist = wishlist.reverse();
    finalResult = [...pinnedWishlist, wishlist];

    return finalResult;
  };

  const getHeight = React.useMemo(() => {
    if (wishlistData.length === 0) return '0';
    if (wishlistData.length === 1) return '200px';
    return '300px';
  }, [wishlistData.length]);

  return (
    <Box css={styWishlishWrapper} id="wishlist__box">
      <Box height={getHeight} overflow="scroll" margin="16px 0">
        <Box>{loading && renderLoading()}</Box>
        <Box paddingBottom="36px">{!loading && renderWishlist()}</Box>
      </Box>
      {wishlistData.length > 2 && (
        <Box width="100%" marginTop="-32px">
          <Center>
            <IconButton
              size="sm"
              icon={<AiOutlineArrowDown />}
              isRound
              className="animation-heart button__arrowdown"
            />
          </Center>
        </Box>
      )}
    </Box>
  );
}

Wishlist.propTypes = {
  loading: bool,
  wishlistData: arrayOf(object),
};

Wishlist.defaultProps = {
  loading: false,
  wishlistData: [],
};

export default Wishlist;