import React from 'react';
import { bool, func, string } from 'prop-types';

import { Button, Drawer, DrawerOverlay, DrawerContent, DrawerBody, Center } from '@chakra-ui/react';

import SwipeComponent from '@components/Common/LoveStorySwipe';

import { IMG_PATTERN_SQUARE } from '@/constants/assets';
import { BUTTON_PROPS } from '@/constants/colors';
import txtWording from './locales';
import data from './api/accomodation-data';

function Accomodation({ onClose, visible, lang }) {
  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent
        maxW="500px"
        bgColor="bgPrimary"
        backgroundImage={`url(${IMG_PATTERN_SQUARE})`}
        bgSize="80%"
      >
        <DrawerBody paddingTop="52px">
          <SwipeComponent bgColor="drawerColor" story={data} />
          <Center>
            <Button
              margin="16px 0 32px 0"
              letterSpacing="2px"
              textTransform="uppercase"
              onClick={onClose}
              bgColor="bgSecondary"
              color="alternativeDark"
              size="sm"
              fontWeight="normal"
              marginTop="16px"
              {...BUTTON_PROPS}
            >
              {txtWording.back[lang]}
            </Button>
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

Accomodation.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
};

Accomodation.defaultProps = {
  lang: 'id',
};

export default Accomodation;
