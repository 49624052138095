import get from '@invitato/helpers/dist/getLocale';

export default {
  dear: get('Kepada Bapak/Ibu/Saudara/i,', 'Dear Mr/Mrs/Ms,'),
  user: get('Keluarga & Teman-teman', 'Family & Friends'),
  invitationMuslim: get(
    `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
    `With the blessing of Allah SWT, we would like to invite you to our wedding ceremony:`,
  ),
  announcementMuslim: get(
    `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:`,
    `With the blessing of Allah SWT, we would like to announce our wedding ceremony:`,
  ),
  invitation: get(
    `Dengan memohon anugerah dan berkat Tuhan, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
    `With the blessing of God, we would like to invite you to our wedding ceremony:`,
  ),
  announcemnt: get(
    `Dengan memohon anugerah dan berkat Tuhan, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:`,
    `With the blessing of God, we would like to announce our wedding ceremony:`,
  ),
  dan: get('dan', 'and'),
  nameayat: get('[Matius 19:6]', '[Matthew 19:6]'),
  ayat: get(
    "Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia.”", 
    "So they are no longer two, but one flesh. Therefore what God has joined together, let no one separate.”"),
  nameayatquran: get('[Q.S. Yasin: 36]', '[Q.S. Yasin: 36]'),
  ayatquran: get(
    "Mahasuci (Allah) yang telah menciptakan semuanya berpasang-pasangan, baik dari apa yang ditumbuhkan oleh bumi dan dari diri mereka sendiri, maupun dari apa yang tidak mereka ketahui.",
    "Exalted is He who created all pairs, from what the earth grows and from themselves and from that which they do not know."),
};