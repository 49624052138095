export default [
  {
    img: `https://user-images.githubusercontent.com/10141928/154054997-279f21c5-5800-4175-b5d4-1bc1b44f9423.jpeg`,
    title: 'The Ritz-Carlton Jakarta, Mega Kuningan',
    content: `
    Address: <br />
Jl. DR. Ide Anak Agung Gde Agung Jl. Mega Kuningan Barat No.1, Kuningan, West Kuningan, Jakarta, South Jakarta City, Jakarta 12950

<br /> </br />
Phone: <br />
(021) 25518888
    `,
  },
  {
    img: `https://user-images.githubusercontent.com/10141928/154054983-ffd10f7e-acda-42f7-9b09-3928f8a5ffbf.jpeg`,
    title: `The Ritz-Carlton Jakarta, Pacific Place`,
    content: `
    Address: <br />
    Sudirman Commercial Business, Jl. Jend. Sudirman No.35, District Jakarta, Kec. Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12190

    <br /><br />
    Phone: <br />
    (021) 25501888
    `,
  },
  {
    img: `https://user-images.githubusercontent.com/10141928/154055008-f0557276-7bdc-4d91-b5b6-fe0bfbad6806.jpeg`,
    title: `JW Marriott Hotel Jakarta`,
    content: `
      Address: <br />
      Kawasan Mega Kuningan, Jalan Dr Ide Anak Agung Gde Agung Kav E.1.2 No 1&2, Jakarta, 12950

      <br /><br />
      Phone: <br />
      (021) 57988888
    `,
  },
  {
    img: `https://user-images.githubusercontent.com/10141928/154054968-826ac188-ffd4-40db-8fc6-3a536f4056f9.jpeg`,
    title: `The Westin Jakarta`,
    content: `
    Address: <br />
    Jalan H.R. Rasuna Said Kav. C-22A, Kecamatan Setiabudi, Daerah Khusus Ibukota Jakarta 12940

    <br />
    <br />
    Phone: <br />
    (021) 27887788
    `,
  },
  {
    img: `https://user-images.githubusercontent.com/10141928/154055011-36693a92-6f48-4764-9683-0352b584525d.jpeg`,
    title: `JS Luwansa (Hotel JS Luwansa)`,
    content: `
      Address: <br />
      Jl. H. R. Rasuna Said No.22, RT.2/RW.5, Karet Kuningan, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12940

      <br />
      <br />
      Phone: <br />
      (021) 29543030
    `,
  },
];
