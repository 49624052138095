import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Center,
  Box,
  Text,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
} from '@chakra-ui/react';

import { IMG_PATTERN_SQUARE } from '@/constants/assets';
import { BUTTON_PROPS } from '@/constants/colors';
import txtWording from './locales';
import FaqData from './api/faq-data';

function FAQComponent({ onClose, visible, lang }) {
  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent
        maxW="500px"
        bgColor="bgPrimary"
        backgroundImage={`url(${IMG_PATTERN_SQUARE})`}
        bgSize="80%"
      >
        <DrawerBody paddingTop="52px">
          <Box
            color="mainColorText"
            bgColor="drawerColor"
            padding="32px 24px"
            borderRadius="16px"
            boxShadow="xl"
          >
            <Text fontSize="xl" fontWeight="bold" margin="8px 0" marginBottom="24px">
              Frequently Asked Questions
            </Text>
            <Accordion allowToggle>
              {FaqData.map((i) => {
                return (
                  <AccordionItem key={i}>
                    <AccordionButton padding="8px 0" {...BUTTON_PROPS}>
                      <Box flex="1" textAlign="left" fontSize="md">
                        {i.question}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Text fontWeight="light">{i.answer}</Text>
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Box>
          <Center>
            <Button
              margin="16px 0 32px 0"
              letterSpacing="2px"
              textTransform="uppercase"
              onClick={onClose}
              bgColor="bgSecondary"
              color="alternativeDark"
              size="sm"
              fontWeight="normal"
              marginTop="16px"
              {...BUTTON_PROPS}
            >
              {txtWording.back[lang]}
            </Button>
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

FAQComponent.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
};

FAQComponent.defaultProps = {
  lang: 'id',
};

export default FAQComponent;
