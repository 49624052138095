export const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'md',
  type: 'text',
  variant: 'outline',
  colorScheme: 'blackAlpha',
  backgroundColor: 'bgSecondary',
  color: 'alternativeDark',
  fontSize: 'md',
  borderRadius: '4px',
  fontFamily: 'body',
  border: 'transparent',
  _placeholder: { color: 'alternativeDark' },
  _focus: { borderColor: 'alternativeDark' },
};

export const FORM_LABEL_PROPS = {
  fontSize: 'md',
  color: 'mainColorText',
  margin: '8px 0',
  fontFamily: 'body',
};

export const LOADING_STATE = {
  GET_DATA: 'GET_DATA',
  SUBMIT_DATA: 'SUMIT_DATA',
  IDLE: 'IDLE',
};

export const ALERT = {
  success: false,
  error: false,
  messageTitle: '',
  messageContent: '',
};

export const ERROR_TYPE = {
  name: undefined,
  ucapan: undefined,
};
