import React from 'react';
import { Box, Heading, Text, Image } from '@chakra-ui/react';

import useGuestInfo from '@/hooks/useGuestInfo';
import useInvitation from '@/hooks/useInvitation';
import WithAnimation from '@/components/Common/WithAnimation';

import ImgBatik from '@/assets/img/batik-top.svg';
import {
  BOY_NAME_NO_TITLE,
  GIRL_NAME_NO_TITLE,
  IS_BOY_FIRST,
  WEDDING_RESEPSI_DATE,
} from '@/constants';

import txt from './locales';
import useLang from '@hooks/useLang';
import { ENABLE_ARAB, MUSLIM_INVITATION } from '@/constants/feature-flags';

function WelcomeV4() {
  const { name } = useGuestInfo();
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box bgColor="bgPrimary">
      <Box position="absolute" width="100%" maxWidth="500px">
        <Image src={ImgBatik} height="100px" position="absolute" left="0" />
        <Image
          src={ImgBatik}
          height="100px"
          position="absolute"
          right="0"
          transform="rotate(90deg)"
        />
      </Box>
      <Box padding="62px 24px">
        {/* Dear Section */}
        <WithAnimation>
          <Box>
            <Text textAlign="center" fontWeight="light" color="mainColorText">
              {txt.dear[lang]}
            </Text>
            <Text color="mainColorText" textAlign="center" fontWeight="bold" fontSize="lg">
              {name ? `${name}` : `${txt.user[lang]}`}
            </Text>
          </Box>
        </WithAnimation>
        {/* Greetings Text */}
        <Box padding="24px 0">
          <WithAnimation>
            <Text
              color="mainColorText"
              fontSize="md"
              textAlign="center"
            >
              {MUSLIM_INVITATION && (
                <>
                  Assalamualaikum Warahmatullahi Wabarakatuh.
                  <br />
                  {isInvitation ? (
                    <>{txt.invitationMuslim[lang]}</>
                  ) : (
                    <>{txt.announcementMuslim[lang]}</>
                  )}
                </>
              )}
              {!MUSLIM_INVITATION && (
                <>{isInvitation ? <>{txt.invitation[lang]}</> : <>{txt.announcemnt[lang]}</>}</>
              )}
            </Text>
          </WithAnimation>
        </Box>
        <WithAnimation>
          <Box paddingBottom="24px" textAlign="center" color="mainColorText">
            <Heading margin="16px 0" textTransform="uppercase" size="lg">
              {IS_BOY_FIRST ? BOY_NAME_NO_TITLE : GIRL_NAME_NO_TITLE}
            </Heading>
            <Text fontFamily="headingAlternative" fontSize="2xl">
              {txt.dan[lang]}
            </Text>
            <Heading margin="16px 0" textTransform="uppercase" size="lg">
              {IS_BOY_FIRST ? GIRL_NAME_NO_TITLE : BOY_NAME_NO_TITLE}
            </Heading>
            {false && (
              <Text marginTop="16px" textAlign="center" fontWeight="light" color="mainColorText">
                {WEDDING_RESEPSI_DATE}
              </Text>
            )}
          </Box>
        </WithAnimation>
        {/* Quotes Section */}
        <WithAnimation>
          <Box>
            {ENABLE_ARAB && (
              <Text textAlign="center" marginTop="16px" color="mainColorText" fontSize="xl">
                {`وَمِنْ اٰيٰتِهٖٓ اَنْ خَلَقَ لَكُمْ مِّنْ اَنْفُسِكُمْ اَزْوَاجًا لِّتَسْكُنُوْٓا اِلَيْهَا وَجَعَلَ بَيْنَكُمْ مَّوَدَّةً وَّرَحْمَةً ۗاِنَّ فِيْ ذٰلِكَ لَاٰيٰتٍ لِّقَوْمٍ يَّتَفَكَّرُوْنَ ٢١`}
              </Text>
            )}
            {!MUSLIM_INVITATION && (
              <Text textAlign="center" color="mainColorText" fontSize="md">
                {txt.ayat[lang]}
              </Text>
            )}

            {!MUSLIM_INVITATION && (
              <Text textAlign="center" marginTop="16px" color="mainColorText" fontSize="md">
                {txt.nameayat[lang]}
              </Text>
            )}

            {MUSLIM_INVITATION && (
              <Text textAlign="center" fontWeight="300" color="mainColorText" fontSize="md">
                {txt.ayatquran[lang]}
              </Text>
            )}
            {MUSLIM_INVITATION && (
              <Text textAlign="center" marginTop="16px" color="mainColorText" fontSize="md">
                {txt.nameayatquran[lang]}
              </Text>
            )}
          </Box>
        </WithAnimation>
      </Box>
      <Box position="absolute" width="100%" maxWidth="500px">
        <Image
          src={ImgBatik}
          height="100px"
          position="absolute"
          left="0"
          marginTop="-100px"
          transform="rotate(270deg)"
        />
        <Image
          src={ImgBatik}
          height="100px"
          position="absolute"
          right="0"
          marginTop="-100px"
          transform="rotate(180deg)"
        />
      </Box>
    </Box>
  );
}

export default React.memo(WelcomeV4);