import React from 'react';
import { string, func, node } from 'prop-types';
import { Box, Text, Center, Button } from '@chakra-ui/react';

import { IMG_PATTERN_SQUARE } from '@/constants/assets';
import { BG_PRIMARY_GRADIENT, BUTTON_PROPS } from '@/constants/colors';

function ActionBox({ actionIcon, title, subTitle, actionText, onActionClick, ...rest }) {
  return (
    <Box
      bgColor="bgPrimary"
      bgSize="initial"
      border="6px solid"
      borderColor="bgSecondary"
      borderRadius="24px"
      textAlign="center"
      padding="42px 16px"
      color="mainLightText"
      backgroundImage={`linear-gradient(${BG_PRIMARY_GRADIENT}, ${BG_PRIMARY_GRADIENT}), url(${IMG_PATTERN_SQUARE})`}
      {...rest}
    >
      <Text fontSize="3xl" fontFamily="heading">
        {title}
      </Text>
      <Text>{subTitle}</Text>
      <Center>
        <Button
          bgColor="mainColorText"
          marginTop="16px"
          onClick={onActionClick}
          borderRadius="32px"
          boxShadow="xl"
          color="alternativeDark"
          padding="8px 16px"
          leftIcon={actionIcon}
          {...BUTTON_PROPS}
        >
          {actionText}
        </Button>
      </Center>
    </Box>
  );
}

ActionBox.propTypes = {
  title: string.isRequired,
  subTitle: string.isRequired,
  actionIcon: node.isRequired,
  actionText: string.isRequired,
  onActionClick: func.isRequired,
};

export default ActionBox;
