import React, { useState } from 'react';
import { string } from 'prop-types';
import { Box, Heading, Center, Image } from '@chakra-ui/react';
import { FaQuoteLeft } from 'react-icons/fa';
import { MdLocalHotel } from 'react-icons/md';

import WithAnimation from '@components/Common/WithAnimation';
import ViewLocationButton from '@components/Common/Buttons/ViewLocation';
import ViewLocationButton2 from '@components/Common/Buttons/ViewLocation2';
import ViewLocationButton3 from '@components/Common/Buttons/ViewLocation3';
import DividerComponent from '@components/Common/Divider';
import QRCard from '@components/Common/QRCard/Lazy';
import ImgBatik from '@/assets/img/batik-top.svg';

import Detail from './Detail';
import ActionBox from './ActionBox';
import Accomodation from './Accomodation';
import FAQComponent from './Faq';

import useLang from '@hooks/useLang';
import useShiftTime from '@/hooks/useShiftTime';
import useInvitation from '@/hooks/useInvitation';
import useGuestInfo from '@/hooks/useGuestInfo';

import { ID_QRCODE } from '@/constants/identifier';
import { BUTTON_PROPS } from '@/constants/colors';
import { ENABLE_ACCOMODATION_SECTION, ENABLE_FAQ_SECTION } from '@/constants/feature-flags';
import {
  WEDDING_AKAD_TIME,
  // WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_AKAD_DRESSCODE,
  // reception
  WEDDING_RESEPSI_DRESSCODE,
  WEDDING_RESEPSI_FULLDATE,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_TIME,
} from '@/constants';
import txt from './locales';

const STATE = {
  accomodation: 'accommodation',
  faq: 'faq',
  iddle: '',
};

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ ...rest }) {
  const time = useShiftTime();
  const lang = useLang();
  const isInvitation = useInvitation();
  const { code } = useGuestInfo();
  const [display, setDisplay] = useState(STATE.iddle);

  const showAdditionalComponent =
    (isInvitation && (ENABLE_FAQ_SECTION || ENABLE_ACCOMODATION_SECTION)) || code;

  return (
    <Box bgColor="bgPrimary" {...rest}>
      <Box position="absolute" width="100%" maxWidth="500px">
        <Image src={ImgBatik} height="100px" position="absolute" left="0" />
        <Image
          src={ImgBatik}
          height="100px"
          position="absolute"
          right="0"
          transform="rotate(90deg)"
        />
      </Box>
      <Box padding="52px 32px" bgRepeat="no-repeat" width="100%" bgSize="100% 100%">
        <Box color="mainColorText" textAlign="center">
          <WithAnimation>
            <Heading marginTop="-16px" fontWeight="normal" fontSize="2xl" textTransform="uppercase">
              {txt.acara[lang]}
            </Heading>
            <DividerComponent margin="24px 0 24px 0" />
          </WithAnimation>
          
          {/* Announcement */}
          {!isInvitation && (
            <Detail
              title={txt.Akad[lang]}
              subTitle={txt.detailAkad[lang]}
              date={lang ==="id" ?"Sabtu, 11 November 2023" :"Saturday, 11 November 2023"}
              time={WEDDING_AKAD_TIME}
              dresscode={false && WEDDING_AKAD_DRESSCODE}
              location={false && WEDDING_AKAD_LOC_NAME}
              locationRoad={false && WEDDING_AKAD_LOC_ROAD}
            />
          )}
          
          {/* Inv General */}
          {isInvitation && false && (
            <>
              <Detail
                title={txt.Akad[lang]}
                subTitle={isInvitation ?txt.detailAkad[lang] :txt.detailAkad[lang]}
                date="Sabtu, 11 November 2023"
                time={WEDDING_AKAD_TIME}
                dresscode={false && WEDDING_AKAD_DRESSCODE}
                location={WEDDING_AKAD_LOC_NAME}
                locationRoad={WEDDING_AKAD_LOC_ROAD}
              />
              <WithAnimation>
                <Center marginTop="16px" paddingBottom="16px">
                  <ViewLocationButton3
                    size="sm"
                    bgColor="bgSecondary"
                    color="alternativeDark"
                    text={txt.seeLocation[lang]}
                    {...BUTTON_PROPS}
                  />
                </Center>
              </WithAnimation>
              <Detail
                title={txt.Resepsi[lang]}
                subTitle={txt.resepsiDetai[lang]}
                time="12.00 - 15.00 WIB"
                date="Minggu, 12 November 2023"
                dresscode={false && WEDDING_RESEPSI_DRESSCODE}
                location="Hotel Sheraton Gandaria City"
                locationRoad="Jl. Arteri Pd. Indah, Jl. Kebayoran Baru, Kota Jakarta Selatan"
              />
            </>
          )}
          
          {/* Inv Shift 1 Akad & Resepsi */}
          {isInvitation && false && (
            <>
              <Detail
                title={txt.Akad[lang]}
                subTitle={isInvitation ?txt.detailAkad[lang] :txt.detailAkad[lang]}
                date="Sabtu, 11 November 2023"
                time={time}
                dresscode={false && WEDDING_AKAD_DRESSCODE}
                location={WEDDING_AKAD_LOC_NAME}
                locationRoad={WEDDING_AKAD_LOC_ROAD}
              />
              <WithAnimation>
                <Center marginTop="16px" paddingBottom="16px">
                  <ViewLocationButton3
                    size="sm"
                    bgColor="bgSecondary"
                    color="alternativeDark"
                    text={txt.seeLocation[lang]}
                    {...BUTTON_PROPS}
                  />
                </Center>
              </WithAnimation>
              <Detail
                title={txt.Resepsi[lang]}
                subTitle={txt.resepsiDetai[lang]}
                time="12.00 - 15.00 WIB"
                date="Minggu, 12 November 2023"
                dresscode={false && WEDDING_RESEPSI_DRESSCODE}
                location="Hotel Sheraton Gandaria City"
                locationRoad="Jl. Arteri Pd. Indah, Jl. Kebayoran Baru, Kota Jakarta Selatan"
              />
            </>
          )}
          
          {/* Inv Shift 2 Akad & Ngunduh Mantu */}
          {isInvitation && (
            <>
              <Detail
                title={txt.Akad[lang]}
                subTitle={txt.detailAkad[lang]}
                date={lang ==="id" ?"Sabtu, 11 November 2023" :"Saturday, 11 November 2023"}
                time={WEDDING_AKAD_TIME}
                dresscode={false && WEDDING_AKAD_DRESSCODE}
                location={false && WEDDING_AKAD_LOC_NAME}
                locationRoad={false && WEDDING_AKAD_LOC_ROAD}
              />
              <Box height="1px" />
              {false && (
                <WithAnimation>
                  <Center marginTop="16px" paddingBottom="16px">
                    <ViewLocationButton3
                      size="sm"
                      bgColor="bgSecondary"
                      color="alternativeDark"
                      text={txt.seeLocation[lang]}
                      {...BUTTON_PROPS}
                    />
                  </Center>
                </WithAnimation>
              )}
              <Detail
                  title="Ngunduh Mantu"
                  subTitle={txt.resepsiDetai[lang]}
                  time={WEDDING_RESEPSI_TIME}
                  date={lang ==="id" ?WEDDING_RESEPSI_FULLDATE :"Sunday, 26 November 2023"}
                  dresscode={false && WEDDING_RESEPSI_DRESSCODE}
                  location={WEDDING_RESEPSI_LOC_NAME}
                  locationRoad={WEDDING_RESEPSI_LOC_ROAD}
              />
            </>
          )}
          
          {/* BOX Button View Location */}
          {isInvitation && false && (
            <WithAnimation>
              <Center marginTop="16px">
                <ViewLocationButton2
                  size="sm"
                  bgColor="bgSecondary"
                  color="alternativeDark"
                  text={txt.seeLocation[lang]}
                  {...BUTTON_PROPS}
                />
              </Center>
            </WithAnimation>
          )}
          
          {isInvitation && (
            <WithAnimation>
              <Center marginTop="16px">
                <ViewLocationButton
                  size="sm"
                  bgColor="bgSecondary"
                  color="alternativeDark"
                  text={txt.seeLocation[lang]}
                  {...BUTTON_PROPS}
                />
              </Center>
            </WithAnimation>
          )}
        </Box>
      </Box>
      
      {showAdditionalComponent && (
        <>
          <Box padding="24px 32px 62px 32px">
            {code && <QRCard name={ID_QRCODE} />}
            {ENABLE_FAQ_SECTION && (
              <ActionBox
                title="Frequently Asked Questions"
                subTitle="If you are an international guest, please read the following FAQ section for immigration purposes:"
                actionText="FAQ SECTION"
                actionIcon={<FaQuoteLeft />}
                onActionClick={() => setDisplay(STATE.faq)}
                marginBottom="32px"
              />
            )}
            {ENABLE_ACCOMODATION_SECTION && (
              <ActionBox
                title="Suggested Accomodation"
                subTitle="If you are an international guest and looking for a comfortable room, please click the button below: "
                actionText="ACCOMODATION"
                actionIcon={<MdLocalHotel />}
                onActionClick={() => setDisplay(STATE.accomodation)}
              />
            )}
          </Box>
          {ENABLE_FAQ_SECTION && (
            <FAQComponent
              lang={lang}
              visible={display === STATE.faq}
              onClose={() => setDisplay(STATE.iddle)}
            />
          )}
          {ENABLE_ACCOMODATION_SECTION && (
            <Accomodation
              lang={lang}
              visible={display === STATE.accomodation}
              onClose={() => setDisplay(STATE.iddle)}
            />
          )}
        </>
      )}
      <Box position="absolute" width="100%" maxWidth="500px">
        <Image
          src={ImgBatik}
          height="100px"
          position="absolute"
          left="0"
          marginTop="-100px"
          transform="rotate(270deg)"
        />
        <Image
          src={ImgBatik}
          height="100px"
          position="absolute"
          right="0"
          marginTop="-100px"
          transform="rotate(180deg)"
        />
      </Box>
    </Box>
  );
}

WeddingSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(WeddingSection);